<template>
  <div>
    <!-- <v-toolbar class="teal lighten-2 white--text"> -->
    <v-app-bar
      height="75px"
      app
      :class="`header-partner-style ${PARTNER_CONFIG.theme_partner.header}`"
    >
      <!-- class="teal lighten-2 white--text" -->
      <span>{{ login }}</span>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      >
      </v-app-bar-nav-icon>
      <v-btn
        v-if="!PARTNER_CONFIG.hasLogo"
        icon
        to="/"
        :class="
          `header-button-partner-style ${PARTNER_CONFIG.theme_partner.headerBtn}`
        "
      >
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <router-link
        to="/"
        tag="span"
        style="cursor:pointer"
        v-show="PARTNER_CONFIG.hasLogo"
      >
        <v-img
          class="logo-partner"
          :height="PARTNER_CONFIG.heightlogo"
          :width="PARTNER_CONFIG.widthlogo"
          src="/../config/logo.png"
        ></v-img>
        <!-- src='/../config/logo7.png' -->
      </router-link>

      <router-link to="/" tag="span" style="cursor:pointer">
        <v-toolbar-title
          :class="
            `text-wrap text-center  ${PARTNER_CONFIG.theme_partner.headerName}`
          "
        >
          {{ PARTNER_CONFIG.partner_name }}
        </v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-md-and-down "> </v-toolbar-items>

      <v-btn
        large
        :class="
          ` hidden-sm-and-down ma-2 header-button-partner-style ${PARTNER_CONFIG.theme_partner.headerBtn}`
        "
        v-for="(item, i) in menuItems"
        :key="`menuItem${i}`"
        :to="item.route"
        @click="funcioncall(item.action)"
      >
        <v-icon class="desctopMenu">{{ item.icon }}</v-icon>
        {{ item.title }}
      </v-btn>
      <v-btn
        :class="PARTNER_CONFIG.theme_partner.headerBtn"
        small
        fab
        style="margin:5px"
        @click="changeLang"
      >
        {{ PARTNER_CONFIG.language }}
      </v-btn>
      <!-- анкета -->

      <!-- язык -->
      <!-- <v-row no-gutters>
        <v-col>
          <v-switch v-model="lang"></v-switch>
        </v-col>
        <v-row no-gutters>
          язык
        </v-row>
      </v-row> -->

      <!-- <v-switch
        v-model="lang"
        inset
        :label="`Switch 1: ${lang.toString()}`"
      ></v-switch> -->
      <!-- /язык -->

      <a
        v-if="this.PARTNER_CONFIG.fulAnketa && this.PARTNER_CONFIG.partner_id"
        :href="
          `https://viza-bezviz.anketa.teeal.pl/?managerid=${this.PARTNER_CONFIG.partner_id}`
        "
        target="_blank"
        rel="noopener noreferrer"
        class="anketa-btn"
      >
        <v-btn
          large
          :class="
            ` hidden-sm-and-down ma-2 header-button-partner-style ${PARTNER_CONFIG.theme_partner.headerBtn}`
          "
        >
          <v-icon class="desctopMenu"> mdi-clipboard-edit-outline</v-icon>
          Анкета
        </v-btn>
      </a>
      <!-- //анкета -->
    </v-app-bar>

    <!-- Мобильное меню -->
    <v-navigation-drawer fixed id="nav-drawer" v-model="drawer">
      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="`drawer${i}`"
          :to="item.route"
        >
          <!-- @click="funcioncall(item.action)" -->
          <v-icon class="mobileMenu">{{ item.icon }} </v-icon>
          <v-list-item-content>
            <v-list-item-title> {{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- анкеты -->

        <v-list-item
          v-if="this.PARTNER_CONFIG.fulAnketa && this.PARTNER_CONFIG.partner_id"
        >
          <v-icon class="mobileMenu">mdi-clipboard-edit-outline </v-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a
                :href="
                  `https://viza-bezviz.anketa.teeal.pl/?managerid=${this.PARTNER_CONFIG.partner_id}`
                "
                target="_blank"
                rel="noopener noreferrer"
                class="anketa-btn-mobile"
                >Анкета</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- //анкеты -->
      </v-list>
    </v-navigation-drawer>

    <!-- /Мобильное меню -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
//import image from "./../config/logo.png";
export default {
  data() {
    return {
      drawer: false,
      lang: ["ua", "ru"]
    };
  },

  computed: {
    ...mapGetters(["PARTNER_CONFIG"]),
    isAuth() {
      return this.$store.getters.getAuth;
    },
    menuItems() {
      let menu = [];

      if (this.PARTNER_CONFIG.language == "ua") {
        menu = [
          {
            title: " Вакансії",
            route: "/",
            icon: "mdi-account-hard-hat"
          },
          {
            title: "Часті питання",
            route: "/faq",
            icon: "mdi-frequently-asked-questions"
          },
          {
            title: "Польский online",
            route: "/9krokow",
            icon: "mdi-access-point"
          },
          {
            title: "Контакти",
            route: "#kontakty",
            icon: "mdi-account-box-outline"
          }
        ];
      } else {
        menu = [
          {
            title: " Вакансии",
            route: "/",
            action: "",
            icon: "mdi-account-hard-hat"
          },
          {
            title: "Частые вопросы",
            route: "/faq",
            action: "",
            icon: "mdi-frequently-asked-questions"
          },
          {
            title: "Польский online",
            route: "/9krokow",
            icon: "mdi-access-point"
          },
          {
            title: "Контакты",
            route: "#kontakty",
            icon: "mdi-account-box-outline"
          }
        ];
      }

      // if (this.PARTNER_CONFIG.fulAnketa) {
      //   menu.push({
      //     title: "Розгорнута анкета",
      //     route: `https://viza-bezviz.anketa.teeal.pl/?managerid=${this.PARTNER_CONFIG.partner_id}`,
      //     icon: "mdi-account-box-outline",
      //   });
      // }

      return menu;
    },
    login() {
      return this.$store.getters.getLogin;
    }
  },
  methods: {
    ...mapActions(["CHANGE_LANG"]),
    changeLang() {
      this.CHANGE_LANG();
    }
    // funcioncall(name) {
    //   // костыль который позволяет запускать функции по клику из меню
    //   console.log(name);
    //   if (name) {
    //     this[name]();
    //   }
    // }
    //   ...mapActions(["GET_PARTNER_CONFIG_FROM_API"])
    // },
    // created() {
    //   this.GET_PARTNER_CONFIG_FROM_API();
  }
};
</script>

<style lang="scss" scoped>
#nav-drawer {
  margin-top: 55px;
}

.logo-partner {
  margin-right: 15px;
}

.mobileMenu {
  margin-right: 15px;
}
.desctopMenu {
  margin-right: 10px;
}

a.anketa-btn {
  text-decoration: none;
}
a.anketa-btn-mobile {
  text-decoration: none;
  color: black;
}
</style>
